import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import { TagItem } from '../../components/tag';

const TagsPage = ({ data }: {
  data: DataType;
}): JSX.Element => (
  <Layout seo={{ title: 'タグ一覧' }}>
    <div className="container">
      <h1 className="title is-size-5">タグ一覧</h1>
      <div className="tile is-ancestor is-flex-wrap-wrap">
        {data.allMarkdownRemark.group.map((tag) => (
          <div className="tile is-2" key={tag.fieldValue}>
            <TagItem
              name={tag.fieldValue}
              count={tag.totalCount}
            />
          </div>
        ))}
      </div>
    </div>
  </Layout>
);

export default TagsPage;

type DataType = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  allMarkdownRemark: {
    group: {
      fieldValue: string;
      totalCount: number;
    }[];
  };
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
